<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Lieu Applications
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Lieu Applications',
              'Create'
            ) && auth.type == 'Regular Contract Staff'
          "
        >
          <v-icon left> mdi-plus </v-icon> New Application
        </v-btn>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Lieu Applications',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Lieu Applications',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your lieu applications</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Lieu Applications',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Lieu Applications',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View lieu applications of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Lieu Applications',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Lieu Applications',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all lieu applications</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.review_status="{ item }">
          <v-chip
            small
            dark
            :color="
              item.review_status == 'APPROVED'
                ? 'green'
                : item.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              item.review_status == "APPROVED"
                ? "Approved"
                : item.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAndStoreMyLieuApplications } from "@/services/myDataService";
import { syncOfflineLieuApplicationChanges } from "@/services/lieuApplicationsService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    tab: 1,
    loading: false,
    search: "",
    headers: [
      {
        text: "Application Id",
        value: "id",
      },
      {
        text: "Date",
        value: "from_date",
      },
      {
        text: "No of days",
        value: "no_of_days",
      },
      { text: "Status", value: "review_status" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
  }),
  async created() {
    this.loading = true;
    await syncOfflineLieuApplicationChanges();
    this.items = await fetchAndStoreMyLieuApplications();
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewLieuApplication",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "true" },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewLieuApplication" });
    },
    changeView() {
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllLieuApplicationList",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubLieuApplicationList",
        });
      }
    },
  },
};
</script>